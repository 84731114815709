import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useMutation } from "react-query";
import { useNotificationStore } from "stores/notifications";

export const logout = () => {
  return axios.post("/logout");
};

export const useLogout = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation(logout, {
    onSuccess: (data) => {
      queryClient.clear();
    },
    onError: (error) => {
      console.error(error);
    },
    ...config,
    mutationFn: logout,
  });
};
