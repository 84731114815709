import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getMyCart = () => {
  return axios.get(`/carts/mine`);
};

export const useMyCart = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["cart"],
    queryFn: () => getMyCart(),
  });
};
