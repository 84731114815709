/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/accessible-emoji */
import { LightningBoltIcon } from "@heroicons/react/solid";
import {  DashboardLoading } from "components/Elements";
import { Notifications } from "components/Notifications/Notifications";
import { AuthProvider } from "lib/auth";
import { queryClient } from "lib/react-query";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

const ErrorFallback = () => {
  useEffect(() => {
    queryClient.clear();
    // clear localstorage on error
    localStorage.clear();
  });

  return (
    <>
      <div
        className="text-gray-800 w-screen h-screen flex flex-col justify-center items-center"
        role="alert"
      >
        <button
          className="p-4 flex flex-row items-center justify-between text-gray-400"
          onClick={() => {
            window.location.reload();
          }}
        >
          <LightningBoltIcon className="h-10 w-10 text-yellow-300" />
          <span>Please login again</span>
        </button>
      </div>
    </>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense fallback={<DashboardLoading />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
              <Notifications />
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
